/***
 *
 * Get the token from cookie and parse it to get valid json
 * CheckCookie function also check for cookies existense
 * Which holds user Informations.
 *
 * Author; Debajit Basu
 * Date: 2nd sep,2019
 *
 * @param: token
 * @return: <JSON>
 *
 */
import Cookie from './cookie';

const AuthCheck = {
    checkAuth(token){
        if(Cookie.getCookie('_accessToken')){
            var base64Url = token ? token.split('.')[1] : Cookie.getCookie('_accessToken').split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);

        }else{
            return false;
        }

    },
    checkCookie(){
        if(Cookie.getCookie('_accessToken') && Cookie.getCookie('_accessRole'))
            return true;
        else
            return false;
    }

}

export default AuthCheck;