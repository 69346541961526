import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Head from 'next/head';
import LogRocket from 'logrocket';
import AuthCheck from '../actions/authCheck';
import Cookie from '../actions/cookie';
import { ToastContainer } from 'react-toastify';
import "../scss/main.scss";

export default function MyApp({ Component, pageProps }) {

    useEffect(() => {
        if(process.env.NEXT_PUBLIC_ENV === "production") {
            LogRocket.init('tixnui/collegify-student', {
                release: '1.0.0',
                dom: {
                    textSanitizer: false,
                    inputSanitizer: true,
                },
                console: {
                    isEnabled: false,
                }
            });
            if(AuthCheck.checkCookie()) {
                const user = AuthCheck.checkAuth(Cookie.getCookie('_accessToken'));
                LogRocket.identify(user.userId, {
                    name: user.firstName + " " + user.lastName,
                    email: user.email,
                    orgName: user.orgName
                });
            }
        }
    }, []);

    return (
        <>
            <Head>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover" />
            </Head>
            <Component {...pageProps} />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover={false}
            />
        </>
    )
}

MyApp.propTypes = {
    Component: PropTypes.func,
    pageProps: PropTypes.object
}